export enum PolicyActions {
  ALL = 'ALL',
  VIEW_DETAIL = 'VIEW_DETAIL',
  EDIT = 'EDIT',
  UPDATE = 'UPDATE',
  HIDE = 'HIDE',
  UPGRADE = 'UPGRADE',
  DOWNGRADE = 'DOWNGRADE',
  ORIGINAL = 'ORIGINAL',
  NONE = 'NONE',
  CANCEL = 'CANCEL',
  CREATE = 'CREATE'
}

export enum PolicyStatus {
  HIDDEN = 'HIDDEN',
  ACTIVE = 'ACTIVE',
  DISABLED = 'DISABLED'
}

export enum TypeDetailPolicy {
  POLICY = 'POLICY',
  LOG = 'LOG'
}

export enum ViewPolicy {
  MANAGEMENT = 'MANAGEMENT',
  LEAD = 'LEAD'
}

export enum UpdateType {
  INITIAL = 'Initial Policy',
  CURRENT = 'Current Policy'
}

export enum SortByEnum {
  NUMBER_ESC = 'number',
  NUMBER_DESC = '-number',

  LEAD_ESC = 'lead',
  LEAD_DESC = '-lead',

  CREATED_AT_ESC = 'createdAt',
  CREATED_AT_DESC = '-createdAt',

  OWNER_ESC = 'owner.fullName',
  OWNER_DESC = '-owner.fullName',

  AGENT_ESC = 'agent.fullName',
  AGENT_DESC = '-agent.fullName',

  STATUS_ESC = 'status.parent.name',
  STATUS_DESC = '-status.parent.name',

  TRACKING_ESC = 'policyTracking.name',
  TRACKING_DESC = '-policyTracking.name',

  MODE_ESC = 'mode.name',
  MODE_DESC = '-mode.name',

  EFFECTIVE_DATE_ESC = 'effectiveDate',
  EFFECTIVE_DATE_DESC = '-effectiveDate',

  COMPANY_ESC = 'product.productType.company.name',
  COMPANY_DESC = '-product.productType.company.name',

  PRODUCT_TYPE_ESC = 'product.productType.name',
  PRODUCT_TYPE_DESC = '-product.productType.name',

  PRODUCT_NAME_ESC = 'product.name',
  PRODUCT_NAME_DESC = '-product.name',

  CONTRIBUTION_YEARS_ESC = 'contributionYear',
  CONTRIBUTION_YEARS_DESC = '-contributionYear',

  CREATED_BY_ESC = 'createdBy.fullName',
  CREATED_BY_DESC = '-createdBy.fullName'
}

export enum PolicyManegementSortFieldEnum {
  NUMBER = 'number',
  LEAD = 'lead.fullName',
  CREATED_AT = 'createdAt',
  OWNER = 'owner.fullName',
  AGENT = 'agent.fullName',
  STATUS = 'status.parent.name',
  TRACKING = 'policyTracking.name',
  EFFECTIVE_DATE = 'effectiveDate',
  MODE = 'mode.name',
  PAYMENT = 'payment',
  TARGET = 'target',
  FACEAMOUNT = 'faceAmount',
  COMPANY = 'product.productType.company.name',
  PRODUCT_TYPE = 'product.productType.name',
  PRODUCT_NAME = 'product.name',
  CONTRIBUTION_YEARS = 'contributionYear',
  COMMISSION_OWNER = 'commissionOwner.fullName',
  COMMISSION_AGENT = 'commissionAgent.fullName',
  COMMISSION_TEAM = 'commissionTeam.name',
  COMMISSION_PERCENTAGE = 'commissionPercentage.name',
  CREATED_BY = 'createdBy.fullName'
}
